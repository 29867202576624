<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 套餐列表 -->
    <Title title="套餐列表"/>
    <div class="ct_box">
      <div class="title_box flex">
        <div class="flex" style="align-items: center;">
          <div class="search_box flex">
            <span class="search"></span>
            <!-- @blur="page = 1;get_list()" -->
            <input @keyup.enter="page = 1; get_list()" type="text" v-model="title" class="search_text"
                   placeholder="输入关键词搜索媒介">
            <button class="search_but" @click.stop="page = 1; get_list()">搜索</button>
          </div>
          <p class="sort">价格排序:</p>
          <ul class="flex sort_ul">
            <li :class="sortType === 0 ? 'liActive' : ''" @click="sortTypeFn(0)">不限</li>
            <li :class="sortType === 1 ? 'liActive' : ''" @click="sortTypeFn(1)">价格升序</li>
            <li :class="sortType === 2 ? 'liActive' : ''" @click="sortTypeFn(2)">价格降序</li>
          </ul>
          <mediaWhiteList/>

        </div>


        <ul class="SetMenu_tab flex">

          <li @click="SetMenuTab(0)" :class="collect == 0 ? 'SetMenu_Show' : ''">全部套餐</li>
          <li @click="SetMenuTab(1)" :class="collect == 1 ? 'SetMenu_Show' : ''">我的收藏</li>
        </ul>
      </div>
      <!-- 优惠套餐 -->
      <el-table :cell-style="finalCellStyle" :header-cell-style="headerStyle" :data="tableData">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="center" inline class="demo-table-expand">
              <el-form-item label="套餐细明:">
                <div class="flex" style="flex-wrap: wrap;">
                  <a :href="item.case_url" target="_blank" class="order_ct" style="font-size: 1.2rem;"
                     v-for="(item, index) in props.row.medium_list">{{
                      item.title
                    }}</a>
                </div>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="套餐名称" prop="title">
        </el-table-column>
        <el-table-column label="价格">
          <template slot-scope="scope">
            ￥{{ scope.row.price.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="媒介总数" prop="num">
        </el-table-column>
        <el-table-column label="备注(仅供参考)">
          <template slot-scope="scope">
            <td_remark :text="scope.row.des"/>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <button class="place_but" @click="handleEdit(1, scope.row)">立即下单</button>
            <div class="flex collect_box" style="justify-content: center;">
              <p v-show="scope.row.is_collect == 0" class="collect" @click="collectGet(scope.row)">收藏</p>
              <p v-show="scope.row.is_collect == 1" class="collect2" @click="collectGet(scope.row)">已收藏
              </p>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page"/>

    </div>
  </div>
</template>

<script>
import Title from '../../components/UserInfoTitle.vue'
import {mapState, mapMutations} from 'vuex'

export default {
  name: '',
  computed: {
    ...mapState(['shopObj', 'FillForm'])
  },
  components: {
    Title,
  },
  created() {
  },
  mounted() {
    this.$store.commit('childTab', '媒介套餐')
    this.get_list()
  },
  data() {
    return {
      title: '',
      sortType: 0,
      collect: 0,
      tableData: [],
      total_page: 0,
      count: 0,
      page: 1,
      shopCar: [],
      fullscreenLoading: false,
    }
  },
  methods: {
    pageChange(pageVal) {
      this.page = pageVal
      this.get_list()
    },
    headerStyle() {
      let style = {
        color: '#168ad9',
        fontSize: "16px",
        textAlign: "center",
      }
      return style
    },
    finalCellStyle(row) {
      let style = {
        textAlign: "center",
        fontSize: "16px"

      };
      return style;
    },

    sortTypeFn(id) {
      this.sortType = id
      this.pageChange(1)
    },
    SetMenuTab(item) {
      this.collect = item
      this.pageChange(1)
    },
    // 结算
    handleEdit(index, row) {
      this.shopCar.push(row)
      // console.log(row);
      this.$set(this.shopCar[0], 'category_id', 0)
      console.log(this.shopCar);
      this.$store.state.shopObj = []
      console.log('shopObj:', this.$store.state.shopObj);
      this.$store.commit('shopPush', this.shopCar)
      if (this.$store.state.fillActive == false) {
        this.$router.push('/user/SetMenu/ManuscriptPreview')
      } else {
        this.$store.commit('childTab', '套餐发布')
        this.$router.push({
          path: '/user/SetMenu/fill?id=8',
        })
      }
    },
    get_list() {
      this.fullscreenLoading = true
      let data = {}
      data.page = this.page
      data.limit = 20
      if (this.title !== '') {
        data.title = this.title
      }
      if (this.sortType != 0) {
        data.sort = 'price'
      }
      if (this.sortType == 1) {
        data.order = 'asc'
      } else if (this.sortType == 2) {
        data.order = 'desc'
      }
      if (this.collect == 1) {
        data.collect = this.collect
      }
      this.curlGet('/api/medium/package', data).then(res => {
        console.log(res);
        if (res.data.code) {
          this.total_page = res.data.data.total_page
          this.count = res.data.data.count
          this.tableData = res.data.data.list
          this.tableData.forEach((item, index) => {
            this.tableData[index].price = parseInt(this.tableData[index].price)
          })
          this.fullscreenLoading = false
        }
      })
    },
    // 收藏
    collectGet(item) {
      this.curlGet('/api/medium/collect', {
        mid: item.id,
        style: 2
      }).then(res => {
        console.log(res)
        if (res.data.code) {
          this.tableData.forEach((elem, index) => {
            if (item.id == elem.id) {
              this.tableData[index].is_collect = !this.tableData[index].is_collect
              console.log(this.tableData[index].is_collect);
              if (this.tableData[index].is_collect) {
                return this.$message({
                  message: '收藏成功',
                  type: 'success',
                  offset: 80,
                })
              } else {
                this.$message({
                  message: '已取消收藏',
                  type: 'warning',
                  offset: 80,
                })
              }
            }
          })

          // this.get_list()
        }
      })
    },
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/SetMenu'
</style>